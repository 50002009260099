import { IUser } from "@/interfaces/user";
import { CrudModel } from "./_crud";

export class userAppModel extends CrudModel {
	constructor() {
		super("app/user");
	}

	async getProfile() {
		return this.get("profile");
	}

	async getWorkActiveUsers() {
		return this.get("workActiveUsers");
	}

	async getAllHealthPromoters(parameters: { page: number; limit: number }) {
		return this.get("healthPromoters", parameters);
	}

	async updateProfile(userInfo: Partial<Pick<IUser, "name" | "lastName" | "email" | "workStatus">>) {
		return this.patch(null, "", userInfo);
	}

	async updatePassword(currentPassword: string, password: string) {
		return this.patch(null, "password", { currentPassword, password });
	}
}

export default new userAppModel();
