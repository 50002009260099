




























































import { Component, Vue } from "vue-property-decorator";

import { hasMissingRequiredKeys } from "@/helpers/index";
import { showErrorAlert, showSuccessAlert } from "@/helpers/index";
import { setErrorsOnForm } from "@/helpers/utils";
import { UserRole } from "@/interfaces/user";
import userAppModel from "@/api/user_app.model";
import userModel from "@/api/user.model";

import EntityCrud from "../entity/EntityCrud.vue";
import CustomInput from "@/layouts/components/Input.vue";
import CustomButton from "@/layouts/components/Button.vue";

@Component({
	components: {
		"entity-crud": EntityCrud,
		"custom-input": CustomInput,
		"custom-button": CustomButton,
	},
})
export default class UpdateProfile extends Vue {
	model: any = null;

	loadingPassword = false;
	loadingProfile = false;

	changeProfileInformation = {
		kind: "changeProfileInformation",
		isReadOnly: true,
		title: "Informações pessoais",
		buttons: [{ label: "Alterar", variant: "tertiary", buttonAction: this.setReadOnly }],
		formData: { name: "", lastName: "", email: "" },
		saveButtons: [{ label: "Salvar" }],
		headers: [
			{ name: "Nome", key: "name", isReadOnly: false, required: true },
			{ name: "Sobrenome", key: "lastName", isReadOnly: false, required: true },
			{
				name: "Email",
				key: "email",
				required: true,
				placeholder: "usuario@journey.com.br",
				error: "",
				isReadOnly: false,
			},
		],
	};
	changePasswordForm = {
		kind: "changePassword",
		isReadOnly: true,
		title: "Acesso à conta",
		buttons: [{ label: "Alterar", variant: "tertiary", buttonAction: this.setReadOnly }],
		formData: { currentPassword: "", password: "" },
		headers: [
			{
				name: "Senha atual",
				key: "currentPassword",
				type: "password",
				required: true,
				placeholder: "Digite sua senha atual",
				error: "",
				isReadOnly: false,
			},
			{
				name: "Nova Senha",
				key: "password",
				type: "password",
				required: true,
				placeholder: "Digite sua nova senha",
				error: "",
				isReadOnly: false,
			},
		],
	};

	completeForm = { sections: [this.changeProfileInformation, this.changePasswordForm] };

	goBack() {
		this.$router.back();
	}

	async updatePassword() {
		this.loadingPassword = true;
		try {
			if (this.$store.state.auth.activeUser?.role === UserRole.ADMINISTRATOR) {
				this.model = userModel;
			} else {
				this.model = userAppModel;
			}
			await this.model.updatePassword(
				this.changePasswordForm.formData.currentPassword,
				this.changePasswordForm.formData.password,
			);
			showSuccessAlert("A senha foi atualizada com sucesso");
		} catch (error: any) {
			console.error(error);
			if (error?.response?.properties) {
				this.completeForm.sections.forEach(section => setErrorsOnForm(section.headers, error?.response?.properties));
			} else {
				showErrorAlert(error.message || "Ocorreu um erro. Tente novamente.");
			}
		}
		this.loadingPassword = false;
	}

	profilePayload(data: { name: string; lastName: string; email: string; role?: UserRole }) {
		this.$store.state.auth.activeUser?.role === UserRole.ADMINISTRATOR
			? (data.role = this.$store.state.auth.activeUser?.role)
			: delete data?.role;
		return data;
	}

	async updateProfile() {
		this.loadingProfile = true;
		try {
			if (this.$store.state.auth.activeUser?.role === UserRole.ADMINISTRATOR) {
				this.model = userModel;
			} else {
				this.model = userAppModel;
			}
			const data = this.profilePayload({
				name: this.changeProfileInformation.formData.name,
				lastName: this.changeProfileInformation.formData.lastName,
				email: this.changeProfileInformation.formData.email,
			});
			const response =
				this.$store.state.auth.activeUser?.role === UserRole.ADMINISTRATOR
					? await this.model.updateProfile(this.$route.params.id, data)
					: await this.model.updateProfile(data);

			showSuccessAlert("Dados atualizados com sucesso");
		} catch (error: any) {
			console.error(error);
			if (error?.response?.properties) {
				this.completeForm.sections.forEach(section => setErrorsOnForm(section.headers, error?.response?.properties));
			} else {
				showErrorAlert(error.message || "Ocorreu um erro. Tente novamente.");
			}
		}
		this.loadingProfile = false;
	}

	setReadOnly(data: any) {
		data.isReadOnly = false;
		return data.headers.forEach((header: any) => {
			header.isReadOnly = false;
		});
	}

	userData: any;

	async getProfile() {
		if (this.$store.state.auth.activeUser?.role === UserRole.ADMINISTRATOR) {
			this.model = userModel;
		} else {
			this.model = userAppModel;
		}
		this.userData = await this.model.getProfile();
		this.completeForm.sections.forEach(section => {
			section.formData = this.userData;
			section.headers.forEach(header => (header.isReadOnly = true));
		});
	}

	mounted() {
		this.getProfile();
	}

	get profileInformationButtonDisabled() {
		return hasMissingRequiredKeys(this.changeProfileInformation.headers, this.changeProfileInformation.formData);
	}

	get changePasswordButtonDisabled() {
		return hasMissingRequiredKeys(this.changePasswordForm.headers, this.changePasswordForm.formData);
	}
}
